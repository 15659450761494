figure {
    display: grid;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;
}
.transitionDescHobbies > * {
    z-index: -2;
    grid-area: 1/1;
    transition: .4s;
}
figure figcaption {
    z-index: -1;
    display: grid;
    align-items: end;
    font-family: sans-serif;
    font-weight: bold;
    color: #0000;
    padding: .75rem;
    background: var(--c,#0009);
    clip-path: inset(0 var(--_i,100%) 0 0);
    -webkit-mask:
      linear-gradient(#000 0 0),
      linear-gradient(#000 0 0);
    -webkit-mask-composite: xor;
    -webkit-mask-clip: text, padding-box;
    margin: -1px;
}
figure:hover figcaption{
    --_i: 0%;
    z-index: -2;
}
figure:hover img {
    z-index: -2;
    transform: scale(1.2);
}
@supports not (-webkit-mask-clip: text) {
    figure figcaption {
     z-index: -2;
     -webkit-mask: none;
     color: #fff;
    }
}
.preTitleStyle {
    z-index: 0;
}
