.a_setup{
    text-decoration: none;
    color: #333 !important;
    height: 24px;
    margin: 0;
}
.nvb_pss{
    background-color: transparent !important;
}
.custom-dropdown .dropdown-toggle::after {
    color: #000;
    margin-left: 5px; /* Adjust space between text and arrow */
    vertical-align: middle; /* Align arrow vertically with text */
    position: relative;
    top: -7px; /* Adjust this value to align vertically */
}
.custom-dropdown2 .dropdown-toggle::after {
    color: #000;
    margin-left: 5px; /* Adjust space between text and arrow */
    vertical-align: middle; /* Align arrow vertically with text */
    position: relative;
    top: -0.5px; /* Adjust this value to align vertically */
}
