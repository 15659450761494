.projClickForModal {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 70%;
    padding: 10px; 
    box-sizing: border-box; 
    height: 100%;
    cursor: pointer;
}
.projDarkBackground {
    position: absolute; 
    bottom: 0; 
    border-radius: 16px; 
    width: 100%; 
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px; 
    box-sizing: border-box;
}