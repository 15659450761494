:root {
    --surface-color: rgba(255, 255, 255, 0.8);
    --curve: 48;
}
  
* {
    box-sizing: border-box;
}

.cardLi {
    height: 475px;
}

.cards {
    display: grid;
    gap: 2rem;
    padding: 0;
    list-style-type: none;
}
  
.card {
    position: relative;
    display: block;
    height: 100%;  
    border-radius: calc(var(--curve) * 0.5px);
    overflow: hidden;
    text-decoration: none;
}
  
.card__image {
    width: 100%;
    height: 475px;
    object-fit: cover;
    object-position: top;
}
  
.card__overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    border-radius: calc(var(--curve) * 1px);
    transform: translateY(100%);
    transition: .3s ease-in-out;
}
  
.card:hover .card__overlay {
    transform: translateY(0);
}

.card:hover .card__description_1 {
    transform: translateY(0);
}
.card:hover .card__description_2 {
    transform: translateY(0);
}
  
.card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .3s ease-in-out;
}
  
.card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;      
    z-index: 1;
}
  
.card:hover .card__header {
    transform: translateY(0);
}
  
.card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;      
    border-radius: 12px;      
}
  
.card__title {
    font-size: 1em;
    margin: 0 0 .3em;
}
  
.card__tagline {
    display: block;
    margin: 1em 0;
    font-size: .8em; 
}
  
.card__status {
    font-size: .8em;
}
  
.card__description_1 {
    position: relative;
    padding: 0 2em 2em;
    margin: 0;
    overflow: hidden;
    transform: translateY(-98px);
    transition: .3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.8);
}
.card__description_2 {
    position: relative;
    padding: 0 2em 2em;
    margin: 0;
    overflow: hidden;
    transform: translateY(-98px);
    transition: .3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.8);
}
.documentpointer:hover {
    cursor: pointer;
    background-color: #EEE;
}

.readmore {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: black;
}
.card:focus {
    outline: 1px dotted #28666e;
}
.card .icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    transform: translateX(var(--link-icon-translate));
    opacity: var(--link-icon-opacity);
    transition: all 0.3s;
}
.card::after {
    position: absolute;
    inset-block: 0;
    inset-inline: 0;
    content: "";
}

.cs { 
    background-color: #1E3A8A !important; 
    color: #FFFFFF !important;
}
.htmlaspx {
    background-color: #8BC34A !important; 
    color: #FFFFFF !important;
}
.sql {
    background-color: #A9A9A9 !important; 
    color: #FFFFFF !important;
}
